<template>
  <el-container>
    <!-- 头 -->
    <el-header>
      <v-header></v-header>
    </el-header>
    <!-- 左边菜单栏 -->
    <el-container>
      <el-aside width="249px">
        <v-sidebar></v-sidebar>
      </el-aside>
    </el-container>
    <div class="crumbs">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <template v-if="$route.meta.parent">
                <el-breadcrumb-item class="main_left"><i class="el-icon-s-grid"></i>{{ this.$route.meta.parent }}</el-breadcrumb-item>
                <el-breadcrumb-item>{{ this.$route.name }}</el-breadcrumb-item>
            </template>
            <template v-else>
                <el-breadcrumb-item><i class="el-icon-s-grid"></i>{{ this.$route.name }}</el-breadcrumb-item>
                <!-- <el-breadcrumb-item><i class="el-icon-s-grid"></i>首页首页首页首页首页首页首页首页首页首页首页</el-breadcrumb-item> -->
            </template>
        </el-breadcrumb>
      </div>
      <!-- 内容栏目 -->
      <el-main :class="{'more': $route.meta.parent}">
        <router-view/>
      </el-main>
  </el-container>
  <!-- <div id="app" class="home">
    <ckeditor :editor="editor" @ready="onReady" v-model="editorData" :config="editorConfig"></ckeditor>
    <button style="margin-top: 20px;" @click="getContent">获取内容</button>
  </div> -->
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue';
import vHeader from '@/components/common/Head.vue';
import vSidebar from '@/components/common/Sidebar.vue';
// import ClassicEditor from '../components/in-editor/core/ckeditor';
// import UploadAdapter from '../components/in-editor/core/uploadImageAdapter';
export default {
  name: 'Home',
  data(){
    return {
      // editor: ClassicEditor,
      // editorData: '<blockquote><p>好好学习，天天向上。</p></blockquote><figure class="image image-style-side"><img src="https://winnorm.oss-cn-chengdu.aliyuncs.com/manage/1620370055169_lawyer_8.jpeg"><figcaption>会议照片</figcaption></figure><p><span style="background-color:rgb(255,255,255);color:rgb(0,0,0);font-size:14px;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 李连杰律师，西南财大法律硕士，四川元诺律师事务所律师。1999年开始从事专职律师工作，主要为公司、企业、事业单位、政府部门提供法律顾问及诉讼服务工作。执业 22 年来，积累了经验，形成了自有特长和风格，擅长风险风控、纠纷化解、商务谈判，赢得客户信赖和肯定。</span></p><p><span style="background-color:rgb(255,255,255);color:rgb(0,0,0);font-size:14px;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 李连杰律师，西南财大法律硕士，四川元诺律师事务所律师。1999年开始从事专职律师工作，主要为公司、企业、事业单位、政府部门提供法律顾问及诉讼服务工作。执业 22 年来，积累了经验，形成了自有特长和风格，擅长风险风控、纠纷化解、商务谈判，赢得客户信赖和肯定。</span></p><p><span style="background-color:rgb(255,255,255);color:rgb(0,0,0);font-size:14px;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 李连杰律师，西南财大法律硕士，四川元诺律师事务律师。1999年开始从事专职律师工作，主要为公司、企业、事业单位、政府部门提供法律顾问及诉讼服务工作。执业 22 年来，积累了经验，形成了自有特长和风格，擅长风险风控、纠纷化解、商务谈判，赢得客户信赖和肯定。</span></p><figure class="image"><img src="https://winnorm.oss-cn-chengdu.aliyuncs.com/manage/1620370055169_lawyer_8.jpeg"><figcaption>参赛的地址</figcaption></figure><p>好好学习，天天向上。</p>',
      // editorConfig:{}
    }
  },
  methods:{
    getContent(){
      console.log(this.editor.content)
      alert(this.editorData)
    },
    // onReady(editor){
    //   console.log('编辑器初始化完成')
    //   // 照片上传配置
    //   editor.plugins.get("FileRepository").createUploadAdapter = loader =>{
    //     return new UploadAdapter(loader)
    //   }
    // }
  },
  components: {
    HelloWorld,
    vHeader,
    vSidebar
  }
}
</script>
<style lang="less">
body{
  padding: 0;
  margin: 0;
}
.el-header {
    background-color: rgb(36,47,66);
}
.el-breadcrumb{
    margin-top: 20px;
    height: 50px;
    margin-left: 215px;
}
// .el-breadcrumb-item{
//   height: 50px;
// }
.crumbs{
    // display: block;
    // float: left;
    line-height: 50px;
    height: 60px;
    // background-color: gray;
}
// .main{
//     background-color: yellow;
//     margin-top: 60px;
//     margin-left: -100px;
// }
.el-main{
    display: block;
    margin-left: 200px;
    // background-color: yellow;
    text-align: left;
    margin-top: -10px;
}
// .more{
//     margin-left: -200px;
// }
.el-icon-s-grid{
    margin-right: 10px;
}
// .main_left{
//     font-weight: bold;
//     font-size: 15px;
// }

// .home{
//   width: 50%;
//   margin: 0 auto;
//   margin-top: 50px;
//   min-height: 500px;
// }
</style>