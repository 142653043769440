<template>
    <div class="sidebar">
        <el-menu v-for="(item, i) in menus" :key="i" :default-openeds="[]" router :default-active="$route.path" theme="dark" class="el-menu-vertical-demo">
            <template v-if="item.hasChild">
                <el-submenu index= "i">
                    <template class="menu-group" #title><i class="menuIcon" :class="item.icon"></i><span class="menuName">{{ item.title }}</span></template>
                    <el-menu-item-group v-for="(itm,j) in item.childrens" :key="j">
                        <el-menu-item :index="itm.path">
                            <template #title><i class="menuIcon" :class="itm.icon"></i><span class="menuName">{{ itm.title }}</span></template>
                        </el-menu-item>
                    </el-menu-item-group>
                </el-submenu>
            </template>
            <template v-else>
                <el-menu-item :index="item.path">
                    <template #title><i class="menuIcon" :class="item.icon"></i><span class="menuName">{{ item.title }}</span></template>
                </el-menu-item>
            </template>
        </el-menu>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                menus: [
                    {title: '首页',icon: 'el-icon-s-home',path: '/index', hasChild: false},
                    {title: '团队成员',icon: 'el-icon-user-solid',path: '/member', hasChild: false},
                    {title:'动态管理',icon: 'el-icon-s-comment', path: '/dynamic', hasChild: false},
                    {title:'元诺建材',icon: 'el-icon-s-management', path: '/building', hasChild: false},
                    {title:'业务领域',icon: 'el-icon-s-operation', path: '/field', hasChild: false},
                    {title: '描述简介',icon: 'el-icon-s-order',path: '/about', hasChild: false},
                    {title: '动态数据',icon: 'el-icon-s-data',path: '/number', hasChild: false},


                    // {title:'聚焦与观察',icon: 'el-icon-video-camera-solid', path: '/focus', hasChild: false},
                    
                    // {title:'元诺公益',icon: 'el-icon-share', path: '/about', hasChild: true,
                    //     childrens: [
                    //         {title:'社会责任',icon: 'el-icon-camera-solid', path: '/duty', hasChild: false},
                    //         {title:'一诺基金',icon: 'el-icon-s-finance', path: '/fund', hasChild: false},
                    //     ]
                    // },
                    // {title:'关于元诺',icon: 'el-icon-info', path: '/about', hasChild: true,
                    //     childrens: [
                    //         {title: '描述简介',icon: 'el-icon-s-order',path: '/about', hasChild: false},
                    //         {title: '团队成员',icon: 'el-icon-user-solid',path: '/member', hasChild: false},
                    //         {title:'公司招聘',icon: 'el-icon-s-platform', path: '/job', hasChild: false},
                    //     ]
                    // },
                ]
            }
        },
        computed:{
            onRoutes(){
                return this.$route.path.replace("/", "")
            }
        }
    }
</script>

<style scoped>
.sidebar{
    display: block;
    position: absolute;
    width: 200px;
    left: 0;
    top: 60px;
    bottom: 0;
    background-color: rgb(50,65,87);
}
.menuIcon{
    color: #fff;
    width: 14px;
    display: inline-block;
}
.menuName{
    margin-left: 15px;
    color: #fff;
    font-size: 16px;
}
.el-menu{
    background-color: rgb(50,65,87);
    color: white;
}

.el-submenu{
    background-color: rgb(50,65,87);
    color: red;
    text-align: left;
}
.el-menu-item:focus,
.el-menu-item:hover{
    outline: 0;
    background-color: rgb(36,47,66);
}
.el-menu-item{
    margin-top: 0;
    background-color: rgb(50,65,87);
    color: #fff;
    font-size: 13px;
    text-align: left;
}
.menu-group:focus,
.menu-group:hover{
    outline: 0;
    background-color: rgb(36,47,66);
}
.el-menu-item-group{
    background-color: rgb(50,65,87);
}

</style>