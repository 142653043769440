import axios from 'axios';
// export const IP = 'http://192.168.0.117:3000';
// export const IP = 'http://192.168.0.118:3000';
export const IP = 'https://api.win-norm.com/';
// 团队成员管理
export const getAllLawyer = params => {return axios.get(`${IP}/api/lawyer/manage/all`, {params: params});};
export const createLawyer = params => {return axios.post(`${IP}/api/lawyer/manage/create`, params);};
export const updateInfoLawyer = params => {return axios.put(`${IP}/api/lawyer/manage/update/info`, params);};
export const updateContentLawyer = params => {return axios.put(`${IP}/api/lawyer/manage/update/content`, params);};
export const deleteLawyer = params => {return axios.delete(`${IP}/api/lawyer/manage/delete`, {params: params});};
export const getCaseLawyer = params => {return axios.get(`${IP}/api/lawyer/manage/case`, {params: params});};
export const updateCaseLawyer = params => {return axios.put(`${IP}/api/lawyer/manage/case`, params);};
// 公司招聘
export const getAllJob = params => {return axios.get(`${IP}/api/job/manage/all`, {params: params});};
export const createJob = params => {return axios.post(`${IP}/api/job/manage/create`, params);};
export const updateInfoJob = params => {return axios.put(`${IP}/api/job/manage/update/info`, params);};
export const updateContentJob = params => {return axios.put(`${IP}/api/job/manage/update/content`, params);};
export const deleteJob = params => {return axios.delete(`${IP}/api/job/manage/delete`, {params: params});};
// 业务领域
// export const getAllField = params => {return axios.get(`${IP}/api/news/manage/field`, {params: params});};
// export const updateContentField = params => {return axios.put(`${IP}/api/news/manage/field`, params);};

export const getPeople = params => {return axios.get(`${IP}/api/news/manage/people`, {params: params});};
export const updatePeople = params => {return axios.put(`${IP}/api/news/manage/people`, params);};

// 动态管理
export const getAllDynamic = params => {return axios.get(`${IP}/api/dynamic/manage/all`, {params: params});};
export const createDynamic = params => {return axios.post(`${IP}/api/dynamic/manage/create`, params);};
export const updateInfoDynamic = params => {return axios.put(`${IP}/api/dynamic/manage/update/info`, params);};
export const updateContentDynamic = params => {return axios.put(`${IP}/api/dynamic/manage/update/content`, params);};
export const getPeopleDynamic = params => {return axios.get(`${IP}/api/dynamic/manage/people`, {params: params});};
export const updatePeopleDynamic = params => {return axios.put(`${IP}/api/dynamic/manage/people`, params);};
export const updateCoverDynamic = params => {return axios.put(`${IP}/api/dynamic/manage/update/cover`, params);};

export const getCaseDynamic = params => {return axios.get(`${IP}/api/dynamic/manage/case`, {params: params});};
export const updateCaseDynamic = params => {return axios.put(`${IP}/api/dynamic/manage/case`, params);};
export const deleteDynamic = params => {return axios.delete(`${IP}/api/dynamic/manage/delete`, {params: params});};
// 封面图片
export const getAllImage = params => {return axios.get(`${IP}/api/image/manage/all`, {params: params});};
export const createImage = params => {return axios.post(`${IP}/api/image/manage/create`, params);};
export const deleteImage = params => {return axios.delete(`${IP}/api/image/manage/delete`, {params: params});};

// 业务领域
export const getAllField = params => {return axios.get(`${IP}/api/field/manage/all`, {params: params});};
export const updateContentField = params => {return axios.put(`${IP}/api/field/manage/update/content`, params);};
export const getPeopleField = params => {return axios.get(`${IP}/api/field/manage/people`, {params: params});};
export const updatePeopleField = params => {return axios.put(`${IP}/api/field/manage/people`, params);};
export const getCaseField = params => {return axios.get(`${IP}/api/field/manage/case`, {params: params});};
export const updateCaseField = params => {return axios.put(`${IP}/api/field/manage/case`, params);};
//  元诺建材
export const getBuilding = params => {return axios.get(`${IP}/api/field/manage/building`, {params: params});};
export const updateBuilding = params => {return axios.put(`${IP}/api/field/manage/building`, params);};
export const getBuildingCase = params => {return axios.get(`${IP}/api/field/manage/building/case`, {params: params});};
export const updateBuildingCase = params => {return axios.put(`${IP}/api/field/manage/building/case`, params);};


// 聚焦与观察
export const getAllFocus = params => {return axios.get(`${IP}/api/focus/manage/all`, {params: params});};
export const createFocus = params => {return axios.post(`${IP}/api/focus/manage/create`, params);};
export const updateInfoFocus= params => {return axios.put(`${IP}/api/focus/manage/update/info`, params);};
export const updateContentFocus = params => {return axios.put(`${IP}/api/focus/manage/update/content`, params);};
export const deleteFocus = params => {return axios.delete(`${IP}/api/focus/manage/delete`, {params: params});};
// 社会责任
export const getAllDuty = params => {return axios.get(`${IP}/api/duty/manage/all`, {params: params});};
export const createDuty = params => {return axios.post(`${IP}/api/duty/manage/create`, params);};
export const updateInfoDuty = params => {return axios.put(`${IP}/api/duty/manage/update/info`, params);};
export const updateContentDuty = params => {return axios.put(`${IP}/api/duty/manage/update/content`, params);};
export const deleteDuty = params => {return axios.delete(`${IP}/api/duty/manage/delete`, {params: params});};
// 一诺基金
export const getAllFund = params => {return axios.get(`${IP}/api/fund/manage/all`, {params: params});};
export const updateInfoFund = params => {return axios.put(`${IP}/api/fund/manage/update/info`, params);};
export const createFund = params => {return axios.post(`${IP}/api/fund/manage/create`, params);};
export const deleteFund = params => {return axios.delete(`${IP}/api/fund/manage/delete`, {params: params});};


// 关于元诺
export const getAllBrief = params => {return axios.get(`${IP}/api/brief/manage/all`, {params: params});};
export const updateContentBrief = params => {return axios.put(`${IP}/api/brief/manage/update/content`, params);};
export const updateContentDes = params => {return axios.put(`${IP}/api/brief/manage/update/brief`, params);};


// 动态数据
export const getAllNumber = params => {return axios.get(`${IP}/api/number/manage/all`, {params: params});};
export const updateNumber = params => {return axios.put(`${IP}/api/number/manage/update`, params);};

// 登录
export const login = params => {return axios.post(`${IP}/api/login/manage/login`, params);};
export const updatePS = params => {return axios.put(`${IP}/api/login/manage/update`, params);};
