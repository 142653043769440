<template>
    <div class="container">
        <div class="title">元诺律师管理平台</div>
        <div class="user">
            <el-dropdown trigger="click" @command="handleClick">
                <span class="el-dropdown-link" style="color: #fff; font-size: 16px">
                    {{ name }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item command="PS">修改密码</el-dropdown-item>
                        <el-dropdown-item command="LG">退出</el-dropdown-item>
                    </el-dropdown-menu>
                </template>
                
            </el-dropdown>
        </div>
        <el-dialog title="密码修改" v-model="dialogVisible" width="22%" >
            <el-form label-width="90px" :model="modify" :rules="rules" ref="modify">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="旧密码" prop="oldPS">
                            <el-input v-model="modify.oldPS" placeholder="请输入旧密码"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="新密码" prop="newPS">
                            <el-input v-model="modify.newPS1" placeholder="请输入新密码"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="确认密码" prop="newPS2">
                            <el-input v-model="modify.newPS2" placeholder="请再次输入新密码"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div class="dialog-footer">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="handleSure" :loading="loading">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {
        updatePS as modify,
    } from "@/api/api";
    const code = "200";
    export default {
        data(){
            return {
                name: '我是用户',
                dialogVisible: false,
                modify: {
                    oldPS: '',
                    newPS1: '',
                    newPS2: ''
                },
                rules:{
                    oldPS: [{required: true, message: '请输入旧密码', trigger: 'blur'}],
                    newPS1: [{required: true, message: '请输入新密码', trigger: 'blur'}],
                    newPS2: [{required: true, message: '请再次输入新密码', trigger: 'blur'}],
                },
                loading: false
            }
        },
        methods:{
            handleClick(command){
                if (command == "PS"){
                    console.log('修改密码')
                    
                    this.$nextTick(function () {
                        this.$refs["modify"].resetFields();
                    })
                    this.dialogVisible = true

                }else {
                    localStorage.removeItem('token')
                    localStorage.removeItem('name')
                    this.$router.push('/login');
                    // this.$message({
                    //     message: "退出成功！",
                    //     type: "success"
                    // });
                }

            },
            handleSure(){
                if(this.modify.newPS1 != this.modify.newPS2){
                    this.$message({
                        message: '新密码两次输入不一致',
                        type: 'warning'
                    })
                    return
                }
                const that = this; 
                this.modify.name = this.name
                this.$refs["modify"].validate(valid =>{
                    if(valid){
                        that.loading = true
                        modify(this.modify).then(res =>{
                            that.loading = false
                            if(res.data.code == code){
                                this.$message({
                                    message: "修改成功",
                                    type: "success"
                                });
                                localStorage.setItem('token',res.data.data.token);
                                localStorage.setItem('name',res.data.data.name);
                                that.dialogVisible = false
                                // that.$router.push('/index');
                            }else{
                                this.$message({
                                    message: res.data.msg,
                                    type: 'warning'
                                })
                            }
                        }).catch(err =>{
                            this.$message({
                                message: err.message,
                                type: 'warning'
                            })
                            this.loading = false
                            that.dialogVisible = false
                        })
                     }
                 })
            }
        },
        mounted(){
            this.name = localStorage.name
        }
    }
</script>

<style scoped>
.container{
    height: 60px;
    display: flex;
}
.title{
    flex: 1;
    color: #fff;
    /*background-color: red;*/
    text-align: left;
    line-height: 60px;
    font-size: 20px;
    margin-left: 80px;
}
.user{
    flex: 1;
    color: #fff;
    text-align: right;
    margin-right: 30px;
    line-height: 60px;
}
.dialog-footer{
    text-align: right;
}
.el-dropdown-link{
    font-weight: bold;
}
</style>